@tailwind base;
@tailwind components;
@tailwind utilities;

.black {
  border: 3px solid rgb(14, 1, 1);
}

.blue {
  border: 3px solid rgb(24, 10, 226);
}

.red {
  border: 2px solid rgb(226, 34, 9);
}

.map-h {
  height: 100vw;
  max-height: 430px;
  /* padding-top: 7px; */
  /* padding-bottom: 10px; */
}

.pt-6 {
  padding-top: 6px;
}

.pl-6 {
  padding-left: 6px;
}

.pb-3p {
  padding-bottom: 3px;
}

.pb-6 {
  padding-bottom: 6px;
}

.p-2 {
  padding: 0.5rem;
}

.gravity-report-button {
  background-color: #1E1E1E;
  width: 100%;
  height: 45px;
  border-radius: 8px;
}

#deepLinkRedirectionDiv {
  display: none;
}

.deepLinkRedirection {
  visibility: hidden;
  /* position: absolute; */
}

.text-underline {
  text-decoration: underline;
}